<div class="flex flex-column gap-4 p-3 surface-ground overflow-auto">
    <nuis-input-text
        [label]="'documents.rename.fileName' | translate"
        [control]="fileNameControl"
        [postfix]="extension()"
        [hint]="'documents.rename.fileNameHint' | translate" />
</div>

<div class="flex gap-3 justify-content-end p-3">
    <p-button
        [label]="'actions.cancel' | translate"
        [text]="true"
        severity="secondary"
        [disabled]="isSaving()"
        (onClick)="cancel()" />
    <p-button [label]="'actions.rename' | translate" [loading]="isSaving()" (onClick)="save()" />
</div>
