<div class="flex flex-column gap-2">
    <div class="flex gap-3 justify-content-between align-items-center">
        <div class="font-bold text-xl">{{ 'notes.notes' | translate }}</div>

        <p-button
            [text]="true"
            icon="pi pi-plus"
            severity="success"
            [pTooltip]="'notes.createHeader' | translate"
            tooltipPosition="left"
            (onClick)="handleCreate()" />
    </div>

    @if (isLoading()) {
        <div class="flex justify-content-center">
            <nuis-loading-indicator />
        </div>
    } @else {
        @if (sortedNotes().length > 0) {
            <div class="flex flex-column gap-2">
                @for (note of sortedNotes(); track note.id) {
                    <nuis-card>
                        <div class="flex gap-3">
                            <nuis-profile-picture [email]="note.creator" />

                            <div class="flex-1 flex flex-column gap-1">
                                <div class="flex justify-content-between text-sm text-500">
                                    <div>{{ note.creator | creator }}</div>
                                    <div>{{ note.timestamp | luxonDateTime }}</div>
                                </div>

                                <div class="flex-1 flex gap-3">
                                    <div class="flex-1 flex flex-column gap-1">
                                        <div class="font-bold">{{ note.subject }}</div>

                                        <div>{{ note.body }}</div>
                                    </div>

                                    <div class="flex align-items-center">
                                        <p-button
                                            [text]="true"
                                            icon="pi pi-trash"
                                            severity="danger"
                                            [pTooltip]="'actions.delete' | translate"
                                            tooltipPosition="left"
                                            [loading]="isRemoving()(note)"
                                            (onClick)="handleRemove(note)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nuis-card>
                }
            </div>
        } @else {
            <nuis-card>
                <div class="text-center text-500">
                    {{ 'noNotesFound' | translate }}
                </div>
            </nuis-card>
        }
    }
</div>
