import { Route } from '@angular/router';

export const coreRoutes: Route = {
    loadComponent: () => import('./core.component'),
    children: [
        {
            path: 'notifications',
            loadComponent: () => import('./pages/notifications/notifications.component'),
        },
    ],
};
