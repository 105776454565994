<nuis-grid
    class="flex-1 overflow-hidden"
    [isLoading]="isLoading()"
    [items]="sortedNotifications()"
    [total]="total()"
    [sortField]="sortField()"
    [sortOrder]="sortOrder()"
    [columns]="gridColumns()"
    (stateChange)="stateChange($event)"
    selectionMode="open"
    (selectRow)="selectRow($event)" />

<ng-template #statusColumn let-item="item">
    <nuis-reference-badge
        class="block w-min"
        [pTooltip]="typed(item).errorMessage ?? undefined"
        tooltipPosition="top"
        [autoHide]="false"
        [value]="item.status"
        [entities]="notificationStatuses()" />
</ng-template>
