import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { QuickNoteDto, statusColorBackgrounds, statusColorBorders, statusColorTexts } from '@nuis/common';
import {
    FormErrorComponent,
    InputTextareaComponent,
    InputTextComponent,
    provideFormConfig,
    showErrors,
} from '@nuis/forms';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { NoteForm } from './note-form.type';

@Component({
    selector: 'nuis-note-form',
    standalone: true,
    imports: [
        NgClass,
        TranslatePipe,
        ButtonModule,
        ChipModule,
        InputTextComponent,
        InputTextareaComponent,
        FormErrorComponent,
    ],
    templateUrl: './note-form.component.html',
    providers: [...provideFormConfig({ showOptionalLabel: false })],
})
export class NoteFormComponent {
    public form = input.required<FormGroup<NoteForm>>();
    public quickNotes = input.required<QuickNoteDto[]>();

    protected chipStyle(note: QuickNoteDto): string {
        const styles = [
            'hover:shadow-2',
            statusColorBackgrounds[note.color],
            statusColorTexts[note.color],
            'border-1',
            statusColorBorders[note.color],
        ];
        return styles.join(' ');
    }

    protected showErrors = showErrors;

    protected useQuickNote(note: QuickNoteDto) {
        this.form().patchValue({
            subject: note.subject,
            body: note.body,
        });
    }
}
