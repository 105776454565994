import { Component, inject, OnInit, signal } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { getFileParts, injectDialogOptions, sanitizeFileName } from '@nuis/common';
import { InputTextComponent, setOrClearError } from '@nuis/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, debounceTime, distinctUntilChanged, from, map, Observable, switchMap, tap } from 'rxjs';
import { CheckFileNameResult } from './check-file-name-result.model';
import { DocumentRenameDialogOptions } from './document-rename-dialog-options.type';

@Component({
    selector: 'nuis-document-rename-dialog',
    standalone: true,
    imports: [TranslatePipe, ButtonModule, InputTextComponent],
    templateUrl: './document-rename-dialog.component.html',
})
export class DocumentRenameDialogComponent implements OnInit {
    private readonly options = injectDialogOptions<DocumentRenameDialogOptions>();
    private readonly dialogRef = inject(DynamicDialogRef);
    private readonly fb = inject(NonNullableFormBuilder);

    protected fileNameControl = this.fb.control<string>('', [Validators.required, Validators.maxLength(250)]);
    protected extension = signal<string>('');
    protected isSaving = signal<boolean>(false);

    public ngOnInit() {
        const { name, extension } = getFileParts(this.options.document.fullName);
        this.fileNameControl.setValue(name);
        this.extension.set('.' + extension);

        this.fileNameControl.valueChanges
            .pipe(
                tap(() => this.fileNameControl.markAsPending()),
                debounceTime(500),
                map((value) => sanitizeFileName(value)),
                distinctUntilChanged(),
                switchMap<string, Observable<CheckFileNameResult>>((value) =>
                    from(
                        value !== name // File was renamed to a new name
                            ? this.options.doesFileExist(value + this.extension())
                            : Promise.resolve(false),
                    ).pipe(
                        map((fileExists) => ({ newName: value, fileExists })),
                        catchError(() => [{ newName: value, errorCheckingFileExists: true }]),
                    ),
                ),
            )
            .subscribe((result) => {
                this.fileNameControl.setValue(result.newName, { emitEvent: false });

                setOrClearError(this.fileNameControl, {
                    key: 'fileNameExists',
                    errorValue: result.fileExists ? true : null,
                });

                setOrClearError(this.fileNameControl, {
                    key: 'fileNameCheckingFailed',
                    errorValue: result.errorCheckingFileExists ? true : null,
                });
            });
    }

    protected cancel() {
        this.dialogRef.close();
    }

    protected async save() {
        this.fileNameControl?.markAsTouched();
        if (this.fileNameControl.invalid || this.fileNameControl.pending) {
            return;
        }

        const result = this.fileNameControl.getRawValue();
        const newFullname = result + this.extension();

        try {
            this.isSaving.set(true);
            await this.options.onSave(newFullname);
            this.dialogRef.close();
        } catch (error) {
            console.error(error);
        } finally {
            this.isSaving.set(false);
        }
    }
}
