import { FormControl, FormGroup, NonNullableFormBuilder, ValidatorFn } from '@angular/forms';
import { isNullOrWhitespace, NoteDto } from '@nuis/common';

export interface NoteForm {
    subject: FormControl<string>;
    body: FormControl<string>;
}

const eitherSubjectOrBodyValidator: ValidatorFn = (control) => {
    if (!(control instanceof FormGroup)) {
        return null;
    }

    const form = control as FormGroup<NoteForm>;
    return isNullOrWhitespace(form.value.subject) && isNullOrWhitespace(form.value.body)
        ? { notesEitherSubjectOrBody: true }
        : null;
};

export function initNoteForm(fb: NonNullableFormBuilder, note: NoteDto | null): FormGroup<NoteForm> {
    return fb.group<NoteForm>(
        {
            subject: fb.control<string>(note?.subject ?? ''),
            body: fb.control<string>(note?.body ?? ''),
        },
        {
            validators: [eitherSubjectOrBodyValidator],
        },
    );
}
