import { Component, computed, inject, input, signal, TemplateRef, viewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto, Paths, ReferenceBadgeComponent, sortByDate, toLabelMap } from '@nuis/common';
import { GridColumn, GridComponent, GridState, RowSelection } from '@nuis/grid';
import { TooltipModule } from 'primeng/tooltip';
import { NotificationDto } from '../../dtos';

@Component({
    selector: 'nuis-notification-list',
    standalone: true,
    imports: [TooltipModule, GridComponent, ReferenceBadgeComponent],
    templateUrl: './notification-list.component.html',
})
export class NotificationListComponent {
    private readonly router = inject(Router);
    private readonly translate = inject(TranslateService);

    public isLoading = input.required<boolean>();
    public notifications = input.required<NotificationDto[]>();
    public messageTypes = input.required<OverviewDto[]>();
    public notificationTypes = input.required<OverviewDto[]>();
    public notificationStatuses = input.required<OverviewDto[]>();

    protected statusColumnRef = viewChild.required<TemplateRef<never>>('statusColumn');

    protected sortedNotifications = computed<NotificationDto[]>(() => {
        const notifications = this.notifications();
        const sortOrder = this.sortOrder() ?? 0;
        const sortField = this.sortField();

        switch (sortField) {
            case 'sentOn':
                return notifications.sort((a, b) => sortByDate(a.sentOn, b.sentOn, sortOrder));
            default:
                return notifications;
        }
    });
    protected total = computed<number>(() => this.notifications().length);
    protected sortField = signal<Paths<NotificationDto> | null>('sentOn');
    protected sortOrder = signal<number | null>(-1);
    protected gridColumns = computed<GridColumn<NotificationDto>[]>(() => {
        const messageTypes = this.messageTypes();
        const notificationTypes = this.notificationTypes();

        const messageTypeLabels = toLabelMap(messageTypes);
        const notificationTypeLabels = toLabelMap(notificationTypes);

        const statusColumnRef = this.statusColumnRef();

        const columns: GridColumn<NotificationDto>[] = [
            { field: 'sentOn', type: 'date-time', label: this.translate.instant('sentOn'), sortable: true },
            {
                field: 'messageType',
                type: 'reference',
                label: this.translate.instant('messageType'),
                resolve: (item) => messageTypeLabels.get(item.messageType) ?? item.messageType,
            },
            {
                field: 'notificationType',
                type: 'reference',
                label: this.translate.instant('notificationType'),
                resolve: (item) => notificationTypeLabels.get(item.notificationType) ?? item.notificationType,
            },
            {
                field: 'status',
                type: 'template',
                label: this.translate.instant('status'),
                templateRef: statusColumnRef,
            },
            { field: 'sender', type: 'text', label: this.translate.instant('sender') },
            { field: 'recipients', type: 'text', label: this.translate.instant('recipient') },
            { field: 'subject', type: 'text', label: this.translate.instant('subject'), maxWidth: 400 },
        ];
        return columns;
    });

    protected typed = (item: unknown) => item as NotificationDto;

    protected async stateChange(state: GridState<NotificationDto>) {
        this.sortField.set(state.sortField);
        this.sortOrder.set(state.sortOrder);
    }

    protected selectRow(event: RowSelection<NotificationDto>) {
        if (event.ctrlKey) {
            window.open(`${window.location.origin}/notifications/${event.item.id}`, '_blank');
        } else {
            this.router.navigate(['/notifications', event.item.id]);
        }
    }
}
